import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TableContext from '../Context/TablePosition';
import { Tabs } from 'antd';
import MalzemeKodu from '../Components/Constraints/MalzemeKodu';
import Color from '../Components/Constraints/Color';
import BodyType from '../Components/Constraints/BodyType';
import MalzemeKoduFront from '../Components/Constraints/MalzemeKoduFront';
import BodyTypeFront from '../Components/Constraints/BodyTypeFront';
import ServiceRequests from '../Components/Constraints/ServiceRequests';
import ColorMatrix from '../Components/Constraints/ColorMatrix';
import SingularParameters from '../Components/Constraints/SingularParameters';
import BetaScreen from '../Components/BetaScreen';

const { TabPane } = Tabs;

const BetaScreenPage = () => {
    const { setCurrentUrl } = useContext(TableContext);
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("1");
    // Tab içeriklerinin yeniden yüklenmesini kontrol etmek için key state'i
    const [tabContentKey, setTabContentKey] = useState({});

    useEffect(() => {
        localStorage.setItem("urlpaintshop", location.pathname);
        setCurrentUrl(localStorage.getItem("urlpaintshop"));
    }, [location.pathname, setCurrentUrl]);

    const handleTabChange = (key) => {
        setActiveTab(key);
        // Tab değiştiğinde ilgili tab'in içerik key'ini güncelle
        setTabContentKey(prev => ({
            ...prev,
            [key]: (prev[key] || 0) + 1
        }));
    };

    // Tab içeriğini saran wrapper component
    const TabContent = ({ children, tabKey }) => {
        return (
            <div key={tabContentKey[tabKey] || 0}>
                {children}
            </div>
        );
    };

    return (
        <div className='w-full'>
            <Tabs
                className='w-full border'
                defaultActiveKey="1"
                activeKey={activeTab}
                onChange={handleTabChange}
                tabBarStyle={{ background: 'rgba(0, 0, 0, 0.85)', accentColor: "yellow", fontFamily: "sans-serif" }}
            >
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Gövde Malzeme Kodu</span>} key="1">
                    <TabContent tabKey="1">
                        <MalzemeKodu />
                    </TabContent>
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Gövde Ön Malzeme Kodu</span>} key="2">
                    <TabContent tabKey="2">
                        <MalzemeKoduFront />
                    </TabContent>
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Gövde Tipi</span>} key="3">
                    <TabContent tabKey="3">
                        <BodyType />
                    </TabContent>
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Gövde Ön Tipi</span>} key="4">
                    <TabContent tabKey="4">
                        <BodyTypeFront />
                    </TabContent>
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Renk</span>} key="5">
                    <TabContent tabKey="5">
                        <Color />
                    </TabContent>
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Servis Talepleri</span>} key="6">
                    <TabContent tabKey="6">
                        <ServiceRequests />
                    </TabContent>
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Renk Matrisi</span>} key="7">
                    <TabContent tabKey="7">
                        <ColorMatrix />
                    </TabContent>
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Tekil Prametreler</span>} key="8">
                    <TabContent tabKey="8">
                        <SingularParameters />
                    </TabContent>
                </TabPane>
                <TabPane tab={<span style={{ color: 'white', marginLeft: "5px" }}>Beta</span>} key="9">
                    <TabContent tabKey="9">
                        <BetaScreen />
                    </TabContent>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default BetaScreenPage;