import { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "axios";
import { apiUrl } from "../config";
import _ from "lodash";

export default function PlcTable() {
    const [modifiedData, setModifiedData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages] = useState(10); // Toplam 10 sayfa olduğunu belirtmişsiniz

    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_SECRET_KEY;

    const getData = async (page = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/getLatestPLCData`, {
                auth: {
                    username: username,
                    password: password
                },
                params: {
                    page: page // Sayfa numarasını params olarak ekliyoruz
                }
            });
            setAllData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(currentPage); // currentPage değiştiğinde getData fonksiyonunu çağır
    }, [currentPage]);

    useEffect(() => {
        const dataList = allData?.map((item, index) => ({
            key: index + 1,
            hangOrCollect: (() => {
                switch (item.hangOrCollect) {
                    case "collect": return "Toplama Noktası"
                    case "hang": return "Asma Noktası"
                    case "hangRework": return "Rework Noktası"
                    default: return "Tanımsız"
                }
            })(),
            hangerNo: item?.hangerNo,
            isEmpty: item?.isEmpty === true ? "Boş" : "Dolu",
            received_at: item?.received_at?.slice(0, 10) + " " + item?.received_at?.slice(11, 16),
            status: item?.status,
        }));

        setModifiedData([...dataList]);
    }, [allData]);

    const filterData = data => formatter => modifiedData.map(item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const handleChange = (pagination, filters, sorter, currentTable) => {
        setFilteredInfo(filters);
        setCurrentPage(pagination.current); // Sayfa değiştiğinde currentPage'i güncelle
    };

    const columns = [
        {
            title: "Okunma Noktası",
            dataIndex: "hangOrCollect",
            key: "hangOrCollect",
            align: "center",
            width: 110,
            onFilter: (value, record) => record.hangOrCollect === value,
            filters: _.uniqWith(filterData(modifiedData)(i => i.hangOrCollect), _.isEqual),
            filteredValue: filteredInfo.hangOrCollect || null,
            filterSearch: true,
        },
        {
            title: "Askı Numarası",
            dataIndex: "hangerNo",
            key: "hangerNo",
            align: "center",
            width: 130,
            onFilter: (value, record) => record.hangerNo === value,
            filters: _.uniqWith(filterData(modifiedData)(i => i.hangerNo), _.isEqual),
            filteredValue: filteredInfo.hangerNo || null,
            filterSearch: true,
        },
        {
            title: "Boş / Dolu",
            dataIndex: "isEmpty",
            key: "isEmpty",
            align: "center",
            width: 100,
            onFilter: (value, record) => record.isEmpty === value,
            filters: _.uniqWith(filterData(modifiedData)(i => i.isEmpty), _.isEqual),
            filteredValue: filteredInfo.isEmpty || null,
            filterSearch: true,
        },
        {
            title: "Okunma Tarihi",
            dataIndex: "received_at",
            key: "received_at",
            align: "center",
            width: 150,
            onFilter: (value, record) => record.received_at === value,
            filters: _.uniqWith(filterData(modifiedData)(i => i.received_at), _.isEqual),
            filteredValue: filteredInfo.received_at || null,
            filterSearch: true,
        },
    ];

    return (
        <div className="flex flex-col overflow-auto">
            <div className="tableAsma flex h-full overflow-auto w-full">
                <Table
                    bordered
                    sticky
                    loading={loading}
                    dataSource={modifiedData}
                    rowClassName={(record, index) => {
                        return index % 2 === 0
                            ? "table-row-light table-height"
                            : "table-row-dark table-height";
                    }}
                    onChange={handleChange}
                    columns={columns}
                    pagination={{
                        current: currentPage,
                        total: totalPages * 10, // Toplam kayıt sayısı (her sayfada 10 kayıt olduğunu varsayıyoruz)
                        pageSize: 10,
                        showSizeChanger: false // Sayfa başına kayıt sayısı değiştirme seçeneğini kapatıyoruz
                    }}
                    scrollToFirstRowOnChange={true}
                />
            </div>
        </div>
    );
}